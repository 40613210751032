<template>
  <v-container class="h-100 dashboard">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
          <Loader />
      </v-col>
    </v-row>

    <PageHeader class="fadeUp"  :subtitle="'Tableau de bord du '+ date" v-if="!loading"></PageHeader>

    <v-row v-if="error">
      <v-col cols="12">
        <v-card class="pt-8 pb-8 pl-8 pr-8 text-center">
          <div class="text-error"><v-icon style="font-size:5em;margin-top:8px;">mdi-alert</v-icon> </div>
          <div class="text-error">{{ this.errorMessage }}</div>
          <div class="fake-link mt-3" @click="this.error=false;this.loading=true;this.loadDashboard()">Ressayer</div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loading && projects['youtrack'] !== undefined">
      <v-col cols="3" class="fadeUp" >
        <v-card>
          <div class="d-flex" style="position:relative;padding-top:5px;">
            <div style="flex:1;padding-left:15px;">
              <div style="font-size:1.2em;font-weight:500;">Ticket Youtrack</div>
              <div style="color:#ccc;font-size:0.9em;">Sur la semaine</div>
              <div style="font-size:1.8em;font-weight:500;margin-top:0px;">{{ projects['youtrack']['count'] }}</div>
            </div>
            <div class="d-flex">
              <v-icon style="font-size:9em;color:#fae3e8;position:absolute;margin-left:-120px;">mdi-table</v-icon>
            </div>
          </div>

        </v-card>
      </v-col>
      <v-col cols="3" class="fadeUp">
        <v-card>
          <div class="d-flex" style="position:relative;padding-top:5px;">
          <div style="flex:1;padding-left:15px;">
            <div style="font-size:1.2em;font-weight:500;">Merge request</div>
            <div style="color:#ccc;font-size:0.9em;">Sur la semaine</div>
            <div style="font-size:1.8em;font-weight:500;margin-top:0px;">{{ projects['mergerequest']['count'] }}</div>
          </div>
            <div class="d-flex">
              <v-icon style="font-size:9em;color:#e3edfa;position:absolute;margin-left:-120px;">mdi-gitlab</v-icon>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="3" class="fadeUp" >
        <v-card>
          <div class="d-flex" style="position:relative;padding-top:5px;">
            <div style="flex:1;padding-left:15px;">
              <div style="font-size:1.2em;font-weight:500;">Temps passé</div>
              <div style="color:#ccc;font-size:0.9em;">Sur la semaine en heure</div>
              <div style="font-size:1.8em;font-weight:500;margin-top:0px;">{{ projects['time']['count'] }}</div>
            </div>
            <div class="d-flex">
              <v-icon style="font-size:9em;color:#faf0e3;position:absolute;margin-left:-120px;">mdi-alarm-check</v-icon>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="3" class="fadeUp" >
        <v-card>
          <div class="d-flex" style="position:relative;padding-top:5px;">
            <div style="flex:1;padding-left:15px;">
              <div style="font-size:1.2em;font-weight:500;">Monitoring</div>
              <div style="color:#ccc;font-size:0.9em;">Sur la semaine</div>
              <div style="font-size:1.8em;font-weight:500;margin-top:0px;">{{ projects['monitoring']['count'] }}%</div>
            </div>
            <div class="d-flex">
              <v-icon style="font-size:9em;color:#e3fae4;position:absolute;margin-left:-120px;">mdi-web</v-icon>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loading && projects['youtrack'] !== undefined">
      <v-col cols="8" class="fadeUp" >
        <v-card style="min-height:200px;padding:20px 25px;">
          <div style="font-size:1.2em;font-weight:500;">Derniers builds</div>
          <table class="custom-table mt-4" style="margin-bottom:-21px;" cellspacing="0">
            <thead>
            <tr>
              <th>Id</th>
              <th>Projet</th>
              <th>Date</th>
              <th>Etat</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="build in projects['builds']">
              <td>#{{ build['id'] }}</td>
              <td>{{ build['project'] }}</td>
              <td>{{ fromNow(build['date']) }}</td>
              <td>
                <div class="bg-warn" v-if="build['state'] === 'pending' || build['state'] === 'running'">
                  <v-icon style="font-size:1em;">mdi-clock</v-icon> En cours
                </div>
                <div class="bg-valid" v-if="build['state'] === 'success'">
                  <v-icon style="font-size:1em;">mdi-check</v-icon> Terminé
                </div>
                <div class="bg-danger" v-if="build['state'] === 'error' || build['state'] === 'canceled' || build['state'] === 'failed'">
                  <v-icon style="font-size:1em;">mdi-information-outline</v-icon> Erreur
                </div>
              </td>
              <td><v-icon>mdi-chevron-right</v-icon></td>
            </tr>

            </tbody>
          </table>
        </v-card>
      </v-col>
      <v-col cols="4" class="fadeUp" >
        <v-card style="min-height:200px;padding:20px 25px;">
          <div style="font-size:1.2em;font-weight:500;">Environnements</div>
          <table class="custom-table custom-table-fixed-height custom-table-no-last-width mt-4" style="margin-bottom:-21px;" cellspacing="0">
            <thead>
            <tr>
              <th>Projet</th>
              <th>Date</th>
              <th>Tag/version</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="env in projects['envs']">
              <td>{{ env['project'] }}</td>
              <td>{{ fromNow(env['date']) }}</td>
              <td>{{ env['tag'] }}</td>
            </tr>
            </tbody>
          </table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loading && projects['youtrack'] !== undefined">
      <v-col cols="6" class="fadeUp" >
        <v-card style="min-height:200px;">
          <div style="font-size:1.2em;font-weight:500;">Tickets Youtracks</div>
          <table class="custom-table custom-table-fixed-height custom-table-no-last-width mt-4" style="margin-bottom:-21px;" cellspacing="0">
            <thead>
            <tr>
              <th>Projet</th>
              <th>Date</th>
              <th>Tag/version</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="ticket in projects['tickets']">
              <td>{{ ticket['project'] !== null ? ticket['project'] : '-' }}</td>
              <td>{{ fromNow(ticket['date']) }}</td>
              <td>{{ '['+ ticket['youtrackId'] +'] ' + ticket['name'] }}</td>
            </tr>
            </tbody>
          </table>
        </v-card>
      </v-col>
      <v-col cols="6" class="fadeUp" >
        <v-card style="min-height:200px;">
          <div style="font-size:1.2em;font-weight:500;">Merges request</div>
          <table class="custom-table custom-table-fixed-height custom-table-no-last-width mt-4" style="margin-bottom:-21px;" cellspacing="0">
            <thead>
            <tr>
              <th>Projet</th>
              <th>Date</th>
              <th>Nom</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="mr in projects['mrs']">
              <td>{{ mr['project'] }}</td>
              <td>{{ fromNow(mr['date']) }}</td>
              <td>{{ mr['name'] }}</td>
            </tr>
            </tbody>
          </table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import Loader from "@/components/ui/Loader.vue";
import StatCard from "@/components/ui/StatCard.vue";
import { format, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
import axios from 'axios';

export default {
  name: 'DashboardPage',
  components: { PageHeader, StatCard, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: null,
      projects: [],
      sync: null,
      date : format(new Date(), "d MMMM yyyy", { locale: fr }),
      hour : parseInt(format(new Date(), "HH", { locale: fr })),
      formatDistanceToNow : formatDistanceToNow,
      cancelTokenSource: axios.CancelToken.source(),
    }
  },
  unmounted() {
    clearInterval(this.sync);
    this.cancelTokenSource.cancel('Request canceled by the user.');
  },
  methods: {
    async loadDashboard() {
      try {
        clearInterval(this.sync);
        await DashboardService.loadDashboard(this.cancelTokenSource).then(response => {
          this.error = false;
          this.sync = setTimeout(() => {this.loadDashboard()}, 3000);
          this.projects = response;
        })
      } catch (error) {
        handleResponseError(error, this.$t)
        this.error = true;
        this.errorMessage = 'Une erreur est survenue';
      } finally {
        this.loading = false
      }
    },
    fromNow(date) {
      return formatDistanceToNow(new Date(date), { addSuffix: true, locale: fr })
    }
  },
  mounted() {
    this.loading = true
    this.loadDashboard();
  }
}
</script>
