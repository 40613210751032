<template>
  <v-row style="margin-top:-30px;">
    <v-col cols="12">
      <v-card style="border-top-left-radius: 0px;border-top-right-radius: 0px;">
        <div class="d-flex" style="height:20px;max-height:30px;margin-top:-7px;margin-bottom:10px;margin-left:-5px;">
          <div class="ml-2 mt-2" style="font-weight:500;" v-if="selectedTable === null">
            Base de données
          </div>
          <div class="flex-grow-1" v-if="selectedTable !== null && mysqlValues.length > 0">
            <input type="text" placeholder="" @keyup="queryMysqlEnter($event, this.usedPod['metadata']['name'], this.selectedTable, this.query)" v-model="query" style="height:35px;border:solid 1Px #eee;width:100%;padding-left:10px;"></input>
          </div>

          <div class="d-flex mt-2 mr-0 pl-4 namespace-btn" @click="mysqlReturnTable()" v-if="selectedTable !== null && mysqlValues.length > 0">
            <v-icon>mdi-table</v-icon>
            <div class="ml-1">Retour aux tables</div>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12 pt-0">
      <v-card class="mt-0">
        <div class="text-center pt-4 pb-4"
             v-if="error">
          Une erreur est survenue, impossible de charger la base de données
        </div>
        <div class="text-center pt-4 pb-4"
             v-if="(mysqlTable.length === 0 || (selectedTable !== null && mysqlValues.length === 0)) && !error">
          Chargement des données ...
        </div>

        <div class="pt-0 pl-0" style="font-size:1.2em;font-weight:400;color:#017ffa;" v-if="selectedTable !== null && mysqlValues.length > 0">
          <v-icon style="font-size:1.5em;">mdi-table</v-icon>
          {{ selectedTable }}
        </div>

        <div v-if="mysqlCols.length > 0 && mysqlValues.length > 0">
          <v-table class="w-100 v-table">
            <thead>
            <tr>
              <th v-for="col in mysqlCols">{{ col['name'] }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in mysqlValues">
              <td v-for="value in row">{{ value.length > 150 ? value.substring(0, 150) + '...' : value }}</td>
            </tr>
            </tbody>
          </v-table>
        </div>

        <div v-if="mysqlCols.length === 0 && selectedTable === null">
          <div v-for="table in mysqlTable">
            <div class="item-link text-primary cursor-pointer pt-2 pb-2" style="border-bottom:solid 1px #eee;"
                 @click="queryMysql(usedPod['metadata']['name'], table[0])">
              <v-icon style="font-size:1.5em;">mdi-table</v-icon>
              {{ table[0] }}
            </div>
          </div>
        </div>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useAuthStore } from '@/stores/AuthStore.js';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import CircularBar from '@/components/ui/progressBar/CircularBar.vue';
import DashboardService from '@/services/DashboardService.js';
import { handleResponseError } from '@/components/utils/globalFunctions.js';

export default {
  name: 'MysqlCard',
  components: { CircularBar },
  props: {
    selectedPod: {
      type: Object,
      required: true,
      default: null
    },
    cluster: {
      type: Number,
      required: true,
      default: 2
    }
  },
  beforeMount() {
    this.usedPod = this.$props.selectedPod;
  },
  data() {
    return {
      usedPod: null,
      mysqlPort: null,
      mysqlTable: [],
      mysqlCols: [],
      selectedTable: null,
      mysqlValues: [],
      editSql: false,
      query: null,
      error: false,
    }
  },
  methods: {
    async loadMysql() {
      this.module = 'mysql';
      this.mysqlTable = [];

      try {
        DashboardService.redirectPod(this.$props.cluster, this.$route.params.id, this.usedPod['metadata']['name'], this.mysqlPort).then(response => {
          this.mysqlTable = response['tables']
          this.mysqlPort = response['port']
        })
      } catch (error) {
        this.error = true;
      } finally {}
    },
    async queryMysqlEnter($event, pod, table, query = null) {
      if($event.code !== 'Enter') {
        return;
      }

      this.queryMysql(pod, table, query);
    },
    async queryMysql(pod, table, query = null) {
      this.selectedTable = table;
      this.mysqlValues = [];
      this.query = (query === null) ? 'SELECT * FROM ' + table + ' LIMIT 0,100' : query;

      DashboardService.mysql(this.$route.params.id, this.$route.params.id, pod, this.mysqlPort, this.query).then(response => {
        this.mysqlCols = response['cols']
        this.mysqlValues = response['tables']

        if (query !== null) {
          this.editSql = false;
        }
      })
    },
    mysqlReturnTable() {
      this.selectedTable = null;
      this.mysqlCols = [];
      this.mysqlValues = [];
      DashboardService.redirectPod(this.$props.cluster, this.$route.params.id, this.usedPod['metadata']['name'], this.mysqlPort).then(response => {
        this.mysqlTable = response['tables']
        this.mysqlPort = response['port']
      })
    },
  },
  mounted() {
    this.loadMysql();
  }
};
</script>

<style scoped>

</style>
