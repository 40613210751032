import { createRouter, createWebHistory } from 'vue-router'
import GuestLayout from '@/layouts/GuestLayout.vue'
import Login from '@/views/auth/LoginPage.vue'
import AppLayout from '@/layouts/AppLayout.vue'
import DashboardPage from '@/views/DashboardPage.vue'
import NamespacePage from "@/views/NamespacePage.vue";
import UsersPage from "@/views/Users/UsersPage.vue";
import UsersAdd from "@/views/Users/UsersAdd.vue";
import UsersEdit from "@/views/Users/UsersEdit.vue";
import ProjectsPage from "@/views/Projects/ProjectsPage.vue";
import ProjectsStat from "@/views/Projects/ProjectsStat.vue";
import ProjectsAdd from "@/views/Projects/ProjectsAdd.vue";
import ProjectsEdit from "@/views/Projects/ProjectsEdit.vue";
import ProjectViewPage from "@/views/Projects/ProjectsView.vue";
import RepositoriesManagePage from '@/views/Gitlabs/RepositoryPage.vue';
import RepositoriesManageVariablePage from '@/views/Gitlabs/RepositoryCi.vue';
import RepositoriesManageVariableEditPage from '@/views/Gitlabs/RepositoryCiEdit.vue';
import RepositoriesManageVariableAddPage from '@/views/Gitlabs/RepositoryCiAdd.vue';
import HomeOfficeListPage from '@/views/homeOffice/HomeOfficeList.vue';
import HomeOfficeAddPage from '@/views/homeOffice/HomeOfficeAdd.vue';
import HomeOfficeAdminListPage from '@/views/homeOfficeAdmin/HomeOfficeAdminList.vue';
import MonitoringListPage from '@/views/Monitoring/MonitoringList.vue';
import GroupsPage from '@/views/Groups/GroupsPage.vue';
import GroupsAdd from '@/views/Groups/GroupsAdd.vue';
import GroupsEdit from '@/views/Groups/GroupsEdit.vue';
import ClustersDevPage from '@/views/ClustersDevPage.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        layout: GuestLayout,
      },
      component: Login,
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      meta: {
        layout: AppLayout,
        requiresAuth: true
      },
      component: DashboardPage,
    },
    {
      path: '/groups',
      name: 'Groups',
      meta: {
        layout: AppLayout,
        requiresAuth: true
      },
      component: GroupsPage,
    },
    {
      path: '/groups/create',
      name: 'GroupsAdd',
      meta: {
        layout: AppLayout,
        requiresAuth: true
      },
      component: GroupsAdd,
    },
    {
      path: '/groups/:id',
      name: 'GroupsEdit',
      meta: {
        layout: AppLayout,
        requiresAuth: true
      },
      component: GroupsEdit,
    },
    {
      path: '/clustersdev',
      name: 'ClusterDev',
      meta: {
        layout: AppLayout,
        requiresAuth: true
      },
      component: ClustersDevPage,
    },
    {
      path: '/monitoring',
      name: 'Monitoring',
      meta: {
        layout: AppLayout,
        requiresAuth: true
      },
      component: MonitoringListPage,
    },
    {
      path: '/namespace/:cluster/:id',
      name: 'Namespace',
      meta: {
        layout: AppLayout,
        requiresAuth: true
      },
      component: NamespacePage,
    },
    {
      path: '/users',
      name: 'Users',
      meta: {
        layout: AppLayout,
      },
      component: UsersPage,
    },
    {
      path: '/users/add',
      name: 'UsersAdd',
      meta: {
        layout: AppLayout,
      },
      component: UsersAdd,
    },
    {
      path: '/users/:id',
      name: 'UsersEdit',
      meta: {
        layout: AppLayout,
      },
      component: UsersEdit,
    },
    {
      path: '/projects',
      name: 'Projects',
      meta: {
        layout: AppLayout,
      },
      component: ProjectsPage,
    },
    {
      path: '/project/:id',
      name: 'ProjectView',
      meta: {
        layout: AppLayout,
      },
      component: ProjectViewPage,
    },
    {
      path: '/projects/add',
      name: 'ProjectsAdd',
      meta: {
        layout: AppLayout,
      },
      component: ProjectsAdd,
    },
    {
      path: '/projects/:id',
      name: 'ProjectsEdit',
      meta: {
        layout: AppLayout,
      },
      component: ProjectsEdit,
    },
    {
      path: '/stats',
      name: 'ProjectsStat',
      meta: {
        layout: AppLayout,
      },
      component: ProjectsStat,
    },
    {
      path: '/stats/:id',
      name: 'ProjectsStatSpec',
      meta: {
        layout: AppLayout,
      },
      component: ProjectsStat,
    },
    {
      path: '/homeoffice',
      name: 'homeOfficePage',
      meta: {
        layout: AppLayout,
      },
      component: HomeOfficeListPage,
    },
    {
      path: '/homeoffice/create',
      name: 'HomeOfficeAddPage',
      meta: {
        layout: AppLayout,
      },
      component: HomeOfficeAddPage,
    },
    {
      path: '/homeoffice/admin',
      name: 'HomeOfficeAdminListPage',
      meta: {
        layout: AppLayout,
      },
      component: HomeOfficeAdminListPage,
    },
    {
      path: '/repositories',
      name: 'RepositoriesManagePage',
      meta: {
        layout: AppLayout,
      },
      component: RepositoriesManagePage,
    },
    {
      path: '/repositories/:id/variables',
      name: 'RepositoriesManageVariablePage',
      meta: {
        layout: AppLayout,
      },
      component: RepositoriesManageVariablePage,
    },
    {
      path: '/repositories/:id/variables/add',
      name: 'RepositoriesManageVariableAddPage',
      meta: {
        layout: AppLayout,
      },
      component: RepositoriesManageVariableAddPage,
    },
    {
      path: '/repositories/:id/variables/:name',
      name: 'RepositoriesManageVariableEditPage',
      meta: {
        layout: AppLayout,
      },
      component: RepositoriesManageVariableEditPage,
    }
  ]
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
