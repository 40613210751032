<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
          <Loader />
      </v-col>
    </v-row>
    <v-row v-if="error">
      <v-col cols="12">
        <v-card class="pt-8 pb-8 pl-8 pr-8 text-center">
          <div class="text-error"><v-icon style="font-size:5em;margin-top:8px;">mdi-alert</v-icon> </div>
          <div class="text-error">{{ this.errorMessage }}</div>
          <div class="fake-link mt-3" @click="this.error=false;this.loading=true;this.loadDashboard()">Ressayer</div>
        </v-card>
      </v-col>
    </v-row>
    <PageHeader subtitle="Cluster kubernetes" v-if="!loading"></PageHeader>


    <v-row v-if="!loading" style="margin-top:-10px;">
      <v-col :cols="12 / ressources.length" v-for="item in ressources">
        <v-card>
          <v-row>
            <v-col cols="12" class="d-flex mb-0 pb-0">
              <div style="background:#f1f4ff;margin-top:3px;border-radius:10px;padding:5px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;"><v-icon style="font-size:1.6em;color:#548bff">mdi-server-outline</v-icon></div>
              <div class="pl-3">
                <div style="font-size:1.1em;font-weight:600;color:#1c1C1C;">{{ item['name'] }}</div>
                <div style="margin-left:-5px;font-size:0.95em;"><v-icon style="color:#bbb" >mdi-memory</v-icon>  <span style="font-size:0.9em;color:#bbb;">{{(item['ram']['used'].toFixed(0))}} / {{item['ram']['total'].toFixed(0)}} Mo</span></div>
              </div>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0" >
              <div class="d-flex mb-1">
                <div class="flex-grow-1">  </div>
                <div style="font-size:0.85em;color:#999;">{{ ((item['ram']['used'] / item['ram']['total']) * 100).toFixed(2) }} %</div>
              </div>
              <ProgressBar class="mt-2" :percent="((item['ram']['used'] / item['ram']['total']) * 100)" :color="(item['ram']['used'] / item['ram']['total'] * 100) < 80 ? '' : 'e36622'"></ProgressBar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <PageTitle><div>Namespaces</div></PageTitle>
    </v-row>
    <v-row v-if="loading">
      <v-col :cols="12">
        <v-card class="pl-4 pb-4 pt-4 pr-4 text-center text-error">
          Chargement en cours ...
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="margin-top:-10px;" class="position-relative" cols="4" v-for="item, key in namespaces" :style="'z-index:' + (999 - key)">
        <PodCard class="fadeUp" :item="item"></PodCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import Loader from "@/components/ui/Loader.vue";
import StatCard from "@/components/ui/StatCard.vue";
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
import ProgressBar from '@/components/ui/progressBar/ProgressBar.vue';
import PodCard from '@/components/ui/bloc/PodCard.vue';
import PageTitle from '@/components/ui/bloc/PageTitle.vue';
import axios from 'axios';

export default {
  name: 'ClustersDevPage',
  components: { PageTitle, PodCard, ProgressBar, PageHeader, StatCard, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: null,
      ressources: [],
      namespaces: [],
      clusters: [],
      projects: [],
      activeCluster: null,
      ramMax: 0,
      sync: null,
      cancelTokenSource: axios.CancelToken.source(),
    }
  },
  unmounted() {
    clearInterval(this.sync);
    this.cancelTokenSource.cancel('Request canceled by the user.');
  },
  methods: {
    loadDashboard() {
      try {
        clearInterval(this.sync);
        let clusterUrl = this.activeCluster !== null ? this.activeCluster['id'] : 2;
        DashboardService.loadClusterDev(clusterUrl, this.cancelTokenSource).then(response => {
          this.ramMax = 0;
          this.error = false;
          this.sync = setTimeout(() => {this.loadDashboard()}, 3000);
          this.ressources = response['nodes'];
          this.namespaces = response['namespaces'];
          this.clusters = response['clusters'];

          if(this.activeCluster === null && response['clusters'][0] !== undefined) {
            this.activeCluster = response['clusters'][0];
          }
        })
      } catch (error) {
        handleResponseError(error, this.$t)
        this.error = true;
        this.errorMessage = (error['response']['data']['error'] !== undefined) ? error['response']['data']['error'] : 'Une erreur est survenue';
      } finally {
        this.loading = false
      }
    },
    selectCluster(item) {
      this.loading = true
      this.activeCluster = item;
      this.loadDashboard();
    }
  },
  mounted() {
    if(!AuthService.hasAccess('dev_cluster')) {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loading = true
    this.loadDashboard();
  }

}
</script>


<style scoped>
@keyframes center-animate {
  0% {
    background:#51a175
  }
  50% {
    background: #199350;
  }
  100% {
    background:#51a175;
  }
}


</style>
