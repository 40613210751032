<template>
  <v-row class="mt-0 d-flex pl-0 mb-3">
    <div class="flex-grow-1 pl-3">
      <div class="page-title" v-if="this.$props.title === undefined">{{ hour > 17 ? 'Bonsoir' : 'Bonjour' }} {{ this.user?.firstname }}</div>
      <div class="page-title" v-if="this.$props.title !== undefined">{{ this.$props.title }}</div>
      <div class="page-subtitle" v-if="this.$props.subtitle === undefined">Nous somme le {{ date }}</div>
      <div class="page-subtitle" v-if="this.$props.subtitle !== undefined">{{ this.$props.subtitle }}</div>
    </div>
    <div class="pr-3">
      <slot></slot>
    </div>
  </v-row>
</template>

<script>
import { useAuthStore } from '@/stores/AuthStore.js';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export default {
  name: 'PageHeader',
  components: {},
  props: {
    title : {
      type : Array,
      required: false
    },
    subtitle : {
      type: String,
      required: false
    }
  },
  data() {
    return {
      hour : parseInt(format(new Date(), "HH", { locale: fr })),
      date : format(new Date(), "d MMMM yyyy", { locale: fr }),
    }
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  computed: {
    user() {
      return this.authStore.user;
    }
  }
};
</script>
