const ResponseConstant = {
   unexpected_error_key: 'UNEXPECTED_ERROR_KEY',
   name_exist: 'UNEXPECTED_NAME_EXIST',
   group_not_exist: 'GROUP_NOT_EXIST',
   group_not_empty: 'GROUP_NOT_EMPTY',
   invalid_body_error_key: 'INVALID_BODY_ERROR_KEY',
   invalid_query_param_error_key: 'INVALID_QUERY_PARAM_ERROR_KEY',
   invalid_jwt_error_key: 'INVALID_JWT_ERROR_KEY',
   expired_jwt_error_key: 'EXPIRED_JWT_ERROR_KEY',
   invalid_token_error_key: 'INVALID_TOKEN_ERROR_KEY',
   invalid_captcha_token_error_key: 'INVALID_CAPTCHA_TOKEN_ERROR_KEY',
   invalid_email_error_key: 'EMAIL_ERROR_KEY',
   invalid_password_error_key: 'PASSWORD_ERROR_KEY',
   invalid_user_error_key: 'INVALID_USER_ERROR_KEY',
   forgot_password_acknowledge_key: 'VALID_FORGOT_PASSWORD',
   reset_user_password_acknowledge_key: 'VALID_RESET_USER_PASSWORD',
}

export default ResponseConstant;
