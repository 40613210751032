<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader />
      </v-col>
    </v-row>
    <v-row v-if="!loading" class="d-flex pr-4 pt-3 pb-1">
      <div class="pl-4 pt-3 flex-grow-1 page-title">
       Gitlab > Variables
      </div>
      <div>
        <router-link :to="'/repositories/'+ $route.params.id +'/variables/add'">
          <v-btn class="bg-primary btn">Ajouter</v-btn>
        </router-link>
      </div>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <div class="d-flex">
            <v-table class="w-100 custom-table ml-0">
              <thead class="text-left">
              <tr>
                <th>Clé</th>
                <th>Type</th>
                <th>Valeur</th>
                <th width="50">Action</th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="project in projects" >
                <td class="text-left">{{ project['key'] }}</td>
                <td class="text-left">{{ project['variable_type'] }}</td>
                <td class="text-left">{{ project['value'].length > 150 ? project['value'].substring(0, 150) + '...' : project['value'] }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="pt-3 pb-3 pr-3 pl-3 cursor-pointer">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </div>
                    </template>
                    <v-list class="custom-vlist">
                      <v-list-item class="pl-0 pr-0">
                        <v-list-item-title class="pt-3 pb-3 pr-6 pl-6 cursor-pointer list-item-title">Actions</v-list-item-title>
                        <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover" @click="editVariables(project['id'], project['key'])"><div class="d-block flex-grow-1 pr-4">Modifier</div><v-icon class="icon">mdi-chevron-right</v-icon></v-list-item-title>
                        <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover" @click="delVariables($route.params.id, project['key'])"><div class="d-block flex-grow-1 pr-4">Supprimer</div><v-icon class="icon">mdi-chevron-right</v-icon></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              </tbody>
            </v-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import ProjectsService from "@/services/ProjectsService.js";
import Loader from "@/components/ui/Loader.vue";
moment.locale('fr');

export default {
  name: 'RepositoriesManageVariablePage',
  computed: {
    fr() {
      return fr
    }
  },
  components: {Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      projects: [],
      date: new Date(),
      moment : moment,
    }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    async loadProjects() {
      this.loading = true
      try {
        ProjectsService.gitlabVariable(this.$route.params.id).then(response => {
          this.projects = response['variables']
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    async deleteUser(id) {
      let check = confirm('Etes vous sure ?')

      if(check) {
        ProjectsService.projectDelete(id).then(() => {
          useSnackbarStore().openSnackbar({
            text: 'Projet supprimé avec succès',
            color: 'success'
          })
          this.loadProjects();
        });
      }

    },
    editVariables(id, key) {
      this.$router.push({ name: 'RepositoriesManageVariableEditPage', params: {id : id, name : key} });
    },
    delVariables(id, key) {
      if (window.confirm("Confirmer la suppression")) {
        ProjectsService.gitlabVariableDelete(id, key).then(response => {
          if(response['success']) {
            this.loadProjects();
          } else {
            alert('erreur');
          }
        })
      }
    }
  },
  mounted() {
    if(localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loading = true;
    this.loadProjects();
  }

}
</script>
