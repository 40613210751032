<template>
  <GuestCard is-logo-centered class="main-login" style="margin-left:-280px;width:calc(100% + 280px);">
    <v-form v-model="isFormValid" @submit.prevent="handleLogin">
      <v-row>
        <v-col cols="12" class="pb-0">
          <div class="form-group">
            <div class="form-label" @click="theme()">Email</div>
            <div class="form-input">
              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  type="email"
                  required
                  autofocus
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">Mot de passe</div>
            <div class="form-input">
              <v-text-field
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  required
                  :rules="passwordRules"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="togglePasswordVisibility"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-2">
        <v-col cols="12" class="text-center">
          <v-btn
            :text="$t('LOGIN_PAGE.BUTTON.LOGIN')"
            :loading="loading"
            :disabled="!isFormValid"
            type="submit"
          />
        </v-col>
      </v-row>
    </v-form>
  </GuestCard>
</template>

<script>
import { useAuthStore } from '@/stores/AuthStore.js'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js';
import { useThemeStore } from '@/stores/ThemeStore.js';

export default {
  components: { GuestCard },
  data() {
    return {
      email: '',
      emailRules: [
        v => {
          if (/.+@.+\..+/.test(v) || v.length === 0) return true

          return 'Le format de l\'email est incorrect';
        }
      ],
      password: null,
      passwordRules: [
        v => {
          if (v || v.length === 0) return true

          return 'Le mot de passe est requis';
        }
      ],
      isFormValid: false,
      showPassword: false,
      loading: false
    }
  },
  methods: {
    useThemeStore,
    async handleLogin() {
      this.loading = true

      const user = {
        email: this.email,
        password: this.password
      }

      try {
        await useAuthStore().login(user)
        await router.push({ name: 'Dashboard' })
      } catch (error) {
        useSnackbarStore().openSnackbar({ title:'Erreur de connexion', text: 'Les identifiants saisies sont inccorect. <br />Veuillez vérifier vos identifiants', color: 'error' })
      } finally {
        this.loading = false
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    theme() {
      useThemeStore().switch();
    }
  },


}
</script>
<style scoped>
button.bg-primary:disabled {
  background: rgba(224, 224, 224, 1) !important;
  color: rgba(130, 130, 130, 1) !important;
}

.main-class {
  margin-left:-250px;
}
</style>
