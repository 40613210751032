<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader />
      </v-col>
    </v-row>
    <PageHeader v-if="!loading" subtitle="Monitoring">
      <div>
        <div style="color:#aaa;font-size:0.9em;margin-top:0px;margin-bottom:5px;text-align:right;">Prochaine actualisation</div>
        <div style="text-align:right;">{{ showRefresh }}</div>

      </div>
    </PageHeader>
    <v-row v-if="!loading">
      <v-col cols="12" v-for="project in requests" >
        <v-card class="d-flex position-relative">
          <div class="flex-grow-1">
            <div class="d-flex">
              <div class="flex-grow-1">
                <div style="font-size:1.3em;font-weight:500;"> {{ project['project']['name'] }}</div>
                <a :href="project['url']" target="_blank" style="color:#0380fa"> {{ project['url'] }}</a>
                <div style="color:#ccc;font-size:0.8em;"> {{ moment(project['recordedAt']['date']).format('DD/MM/YYYY HH:mm:ss') }} </div>
              </div>
              <div>
                <div class="w-100 text-left mb-1 d-flex" style="font-weight:500;font-size:0.9em;color:#a1a0a0;min-width:250px;">
                  <div class="flex-grow-1">Dernières 24h</div>
                  <div class="ml-4">{{ project['availability'] }} %</div>
                </div>
                <div class="d-flex">
                  <v-tooltip v-for="plot in project['reports']" location="top" :text="plot['date'] + ' ( '+plot['availability']+'% )'">
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" :style="{background: plotColor(plot['availability'])}" style="margin-right:4px;width:6px;border-radius:2px;height:25px;opacity:1;"></div>
                    </template>
                  </v-tooltip>
                </div>
              </div>
            </div>

            <v-divider class="mb-4 mt-3" style="margin-left:-20px;width:calc(100% + 40px)"></v-divider>

            <div class="mt-4 d-flex">
              <div class="flex-grow-1 text-center">
                <div style="color:#a1a0a0">Temps de réponse</div>
                <div style="font-size:1.1em;font-weight:300;"><v-icon>mdi-approximately-equal</v-icon>
                  {{ (project['delayAverage'] * 1000).toFixed(0) }} ms</div>

              </div>
              <div class="flex-grow-1 text-center">
                <div style="color:#a1a0a0">Statut</div>
                <div style="font-size:0.9em;font-weight:300;">
                  <div class="bg-valid" v-if="project['state'] === 'up'">
                    <v-icon style="font-size:1em;">mdi-check</v-icon> Disponible
                  </div>
                  <div class="bg-danger" v-if="project['state'] === 'down'">
                    <v-icon style="font-size:1em;">mdi-information-outline</v-icon> Indisponible
                  </div>
                </div>
              </div>

              <div class="flex-grow-1 text-center">
                <div style="color:#a1a0a0">Temps minimum</div>
                <div style="font-size:1.1em;font-weight:300;"><v-icon>mdi-download</v-icon>
                  {{ (project['responseTime']['min'] * 1000).toFixed(0) }} ms</div>

              </div>


              <div class="flex-grow-1 text-center">
                <div style="color:#a1a0a0">Temps maximum</div>
                <div style="font-size:1.1em;font-weight:300;"><v-icon>mdi-upload</v-icon>
                  {{ (project['responseTime']['max'] * 1000).toFixed(0) }} ms</div>

              </div>

            </div>
          </div>
        </v-card>
      </v-col>


    </v-row>
  </v-container>
</template>

<script>
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import {fr} from "date-fns/locale";
import moment from 'moment-timezone';
import "moment/locale/fr";
import MonitoringService from "@/services/MonitoringService.js";
import Loader from "@/components/ui/Loader.vue";
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
import { formatDistanceToNow } from 'date-fns';
moment.locale('fr');

export default {
  name: 'MonitoringListPage',
  computed: {
    fr() {
      return fr
    }
  },
  components: { PageHeader, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      sync: null,
      requests: [],
      date: new Date(),
      moment : moment,
      nextRefresh: null,
      showRefresh: null,
    }
  },
  methods: {
    plotColor(percent) {
      if(percent === 100) {
        return '#7fca84';
      } else if(percent >= 99) {
        return '#a8ebb9';
      } else if(percent >= 90) {
        return '#ea9009';
      } else {
        return '#d75e7a';
      }
    },
    async loadMonitoring() {
      this.loading = true
      try {
        MonitoringService.list().then(response => {
          this.requests = response['projects'];
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    fromNow(date) {
      this.showRefresh = formatDistanceToNow(new Date(date), { addSuffix: true, locale: fr, includeSeconds: true })
    },
  },
  mounted() {
    this.loading = true;
    this.loadMonitoring();

    this.sync = setInterval(()=> {
      let currentTime = moment();
      this.fromNow(moment().clone().add(5 - (currentTime.minute() % 5), 'minutes').seconds(0));

      if(this.nextRefresh < currentTime) {
        this.nextRefresh = moment().clone().add(5 - (currentTime.minute() % 5), 'minutes').seconds(0);
        this.loadMonitoring();
      }
    }, 1000)
  },
  unmounted() {
    clearInterval(this.sync);
  },
  beforeMount() {
    let currentTime = moment();
    this.nextRefresh = moment().clone().add(5 - (currentTime.minute() % 5), 'minutes');
  }

}
</script>
