<template>
  <v-table class="w-100 custom-table ml-0">
    <thead class="text-left">
    <tr>
      <th v-for="column in this.$props.columns">{{ column['name'] }}</th>
      <th width="50">Action</th>
    </tr>

    </thead>
    <tbody>
    <tr v-for="item in this.$props.datas">
      <td class="text-left" v-for="column in this.$props.columns"><slot :name="column['id']"></slot>{{ searchInData(item, column['id']) }}</td>
      <td>
       <slot name="action" :item="item"></slot>
      </td>
    </tr>
    </tbody>
  </v-table>
</template>

<script>
export default {
  name: 'QuantTable',
  components: {},
  props: {
    columns : {
      type : Array,
      required: true
    },
    datas : {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    searchInData(data, column) {
      if(column.includes('.')) {
        let split = column.split('.');
        let search = data;

        split.forEach(key => {
          search = search[key];
        })

        return search;
      } else {
        return data[column];
      }
    }
  }
};
</script>
