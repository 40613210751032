import api from '@/services/ApiService'

class AuthService{
  async login(userData) {
    const response = await api.post('/auth/login', {
      email: userData.email,
      password: userData.password
    })
    const token = {
      access_token: response.data.accessToken,
      refresh_token: response.data.refreshToken
    }
    localStorage.setItem('token', JSON.stringify(token))
    localStorage.setItem('type', response.data.type)
    return token
  }

  async me() {
    const response = await api.get('/auth/me')
    localStorage.setItem('perms', JSON.stringify(response.data['perms']))
    return response.data
  }

  hasAccess(name) {
    let perms = JSON.parse(localStorage.getItem('perms'));

    return perms.includes(name);
  }

  async logout() {
    try {
      await api.get('/auth/logout')
    } catch (error) {
      // Do nothing
      // If logout comeback with error, shutdown error and clean storage.
      // Only one thing still up, is token entity on the DB. Not problem for now.
    } finally {
      localStorage.removeItem('token')
    }
  }

  async forgotPassword(email) {
    const response = await api.post('/forgot-password', { email: email })
    return response.data
  }

  async resetPassword(user) {
    const response = await api.post('/reset-password', user)
    return response.data
  }
}

export default new AuthService()
