<template>
  <v-row style="margin-top:-30px;">
    <v-col cols="12">
      <v-card style="border-top-left-radius: 0px;border-top-right-radius: 0px;">
        <div class="d-flex" style="height:20px;max-height:30px;margin-top:-7px;margin-bottom:10px;margin-left:-5px;">
          <div class="ml-2 mt-2 flex-grow-1" style="font-weight:500;">
            {{filePath === '' ? path : filePath}}
          </div>
          <div v-if="filePath !== ''" @click="downloadFile()" class="pt-1 item-link">
            <v-icon>mdi-download</v-icon> Télécharger
          </div>
          <div v-if="filePath !== ''" @click="closeFile()" class="pt-1 item-link ml-3">
            <v-icon>mdi-close</v-icon> Fermer
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="fileData !== ''">
      <v-card class="mt-0">
        <div v-html="formatFile()"></div>
      </v-card>
    </v-col>

    <v-col cols="12 pt-0" v-if="filePath === ''">
      <v-card class="mt-0">
        <div  @click="backTo()" v-if="path !== defaultPath">
          <v-icon>mdi-folder</v-icon>  ..
        </div>
        <div class="text-left pt-2 pb-2 item-link item-link-bg d-flex" v-for="dir in dirs" @click="navigateTo(dir)">
          <div class="flex-grow-1">
            <v-icon>{{ dir['type'] === 'dir' ? 'mdi-folder' : 'mdi-file-outline' }}</v-icon>
            {{ dir['name'] }}
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DashboardService from '@/services/DashboardService.js';
import axios from 'axios'

export default {
  name: 'ThreeCard',
  components: {},
  props: {
    selectedPod: {
      type: Object,
      required: true,
      default: null
    },
    cluster: {
      type: Number,
      required: true,
      default: 2
    }
  },
  beforeMount() {
    this.usedPod = this.$props.selectedPod;
  },
  data() {
    return {
      usedPod: null,
      cancelTokenSource: axios.CancelToken.source(),
      dirs: [],
      defaultPath: null,
      path: null,
      error: false,
      fileData: '',
      filePath: ''
    }
  },
  methods: {
    async loadThree() {
      this.module = 'mysql';
      this.mysqlTable = [];

      try {
        DashboardService.loadDirectories(this.$props.cluster, this.$route.params.id, this.usedPod['metadata']['name'], this.cancelTokenSource).then(response => {
          this.dirs = response['elements'];
          this.defaultPath = response['defaultPath'];

          if(this.path === null)
          this.path = response['defaultPath'];
        })
      } catch (error) {
        this.error = true;
      } finally {}
    },
    async navigateTo(item) {
      if(item['type'] === 'dir') {
        this.path = this.path + '/' + item['name'];

        this.dirs = [];
        DashboardService.loadDirectories(this.$props.cluster, this.$route.params.id, this.usedPod['metadata']['name'], this.cancelTokenSource, this.path).then(response => {
          this.dirs = response['elements'];
          this.defaultPath = response['defaultPath'];
        })
      } else {
        let filePath = this.path + '/' + item['name'];;
        DashboardService.loadFile(this.$props.cluster, this.$route.params.id, this.usedPod['metadata']['name'], this.cancelTokenSource, filePath).then(response => {
          this.fileData = response;
          this.filePath = filePath;
        })
      }
    },
    async backTo() {
        let explodePath = this.path.split('/');
        let newPath = '';

        explodePath.pop();
        explodePath.forEach(subpath => {
          if(newPath === '') {
            newPath = subpath;
          } else {
            newPath = newPath + '/' + subpath;
          }
        });

        if (!newPath.startsWith('/')) {
          newPath = '/' + newPath;
        }

        this.path = newPath;

        this.dirs = [];
        DashboardService.loadDirectories(this.$props.cluster, this.$route.params.id, this.usedPod['metadata']['name'], this.cancelTokenSource, this.path).then(response => {
          this.dirs = response['elements'];
          this.defaultPath = response['defaultPath'];
        })
    },
    formatFile() {
      return this.fileData.replace(/\n/g, '<br />');
    },
    closeFile() {
      this.filePath = '';
      this.fileData = '';
    },
    downloadFile() {
        let filePathSplit = this.filePath.split('/');
        let filename = filePathSplit[filePathSplit.length - 1];

        // Créer un objet Blob avec le contenu
        const blob = new Blob([this.fileData], { type: "text/plain" });

        // Créer une URL pour le Blob
        const url = URL.createObjectURL(blob);

        // Créer un lien temporaire pour le téléchargement
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // Nom du fichier téléchargé

        // Ajouter et déclencher le clic
        document.body.appendChild(a);
        a.click();

        // Nettoyer
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }
  },
  mounted() {
    this.loadThree();
  }
};
</script>

<style scoped>

</style>
