<template>
  <div class="pl-4 pt-3 page-title" style="font-size:1.5em;font-weight:500;margin-bottom:15px;">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  components: {}
};
</script>
