<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader/>
      </v-col>
    </v-row>

    <PageHeader subtitle="Gestion des groupes" v-if="!loading">
      <router-link :to="'/groups/create'">
        <v-btn class="bg-primary btn">
          <v-icon>mdi-plus</v-icon>
          Ajouter un groupe
        </v-btn>
      </router-link>
    </PageHeader>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card class="p-0">
          <div class="d-flex">
            <QuantTable :datas="this.groups" :columns="table">
              <template v-slot:action="{ item }">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <div v-bind="props" class="pt-3 pb-3 pr-3 pl-3 cursor-pointer">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </div>
                  </template>
                  <v-list class="custom-vlist">
                    <v-list-item class="pl-0 pr-0">
                      <v-list-item-title class="pt-3 pb-3 pr-6 pl-6 cursor-pointer list-item-title">Actions
                      </v-list-item-title>
                      <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover"
                                         @click="editGroup(item['id'])">
                        <div class="d-block flex-grow-1 pr-4">Editer</div>
                        <v-icon class="icon">mdi-chevron-right</v-icon>
                      </v-list-item-title>
                      <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover"
                                         @click="deleteGroup(item['id'])">
                        <div class="d-block flex-grow-1 pr-4">Supprimer</div>
                        <v-icon class="icon">mdi-chevron-right</v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </QuantTable>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import { fr } from 'date-fns/locale';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import Loader from '@/components/ui/Loader.vue';
import GroupsService from '@/services/GroupsService.js';
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
import QuantTable from '@/components/ui/table/QuantTable.vue';
import AuthService from '@/services/AuthService.js';

moment.locale('fr');

export default {
  name: 'GroupsPage',
  computed: {
    fr() {
      return fr
    }
  },
  components: { QuantTable, PageHeader, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      groups: [],
      date: new Date(),
      moment: moment,
      table: [
        { 'id': 'id', 'name': 'Id' },
        { 'id': 'name', 'name': 'Nom du groupe' },
        { 'id': 'count.permissions', 'name': 'Permissions' },
        { 'id': 'count.users', 'name': 'Utilisateurs' }
      ]
    }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    async loadGroup() {
      this.loading = true
      try {
        await GroupsService.groupList().then(response => {
          this.groups = response;
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    async deleteGroup(id) {
      this.loading = true;
      try {
        await GroupsService.delete(id).then(response => {
          this.loadGroup();
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    async editGroup(id) {
      this.loading = true;
      this.$router.push({ name: 'GroupsEdit', params: {'id' : id} });
    }
  },
  mounted() {
    this.loading = true;
    if (!AuthService.hasAccess('group_manage')) {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loadGroup();
  }

}
</script>

<style scoped>

</style>
