<template>
  <v-container class="h-100 pt-4">
    <div v-if="modalEnv" style="padding:20px;width:50vh;min-width:500px;height:100vh;background:#fff;position:fixed;right:0;z-index:9999;top:0;box-shadow: 0 0 9px 0 rgba(0,0,0,0.1)!important;">
      <div style="position:fixed;right:20px;top:20px;" @click="modalEnv=false"><v-icon>mdi-close</v-icon></div>
      <div style="font-size:1.4em;font-weight:500;">Modification d'environnement</div>
      <div class="mt-5">
        <v-form v-model="isFormValid">
          <div class="form-group">
            <div class="form-label">Nom de la variable</div>
            <v-text-field :disabled="true" v-model="modalEnvform.name" />
          </div>
          <div class="form-group">
            <div class="form-label">Type de la variable</div>
            <v-select v-model="modalEnvform.type" :disabled="true" :items="['file', 'env_var']"></v-select>
          </div>
          <div class="form-group">
            <div class="form-label">Contenu</div>
            <v-textarea rows="15" v-model="modalEnvform.value" />
          </div>

          <v-row>
            <v-col cols="6">
              <v-btn
                  text="Modifier et fermer"
                  :loading="loading"
                  :disabled="!isFormValid"
                  type="button"
                  @click="sendForm(false)"
              />
            </v-col>
            <v-col cols="6">
              <v-btn
                  text="Modifier et appliquer"
                  :loading="loading"
                  :disabled="!isFormValid"
                  type="button"
                  @click="sendForm(true)"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader />
      </v-col>
    </v-row>
    <PageHeader :subtitle="'namespace ' + this.$route.params.id" v-if="!loading">
      <div>
        <v-btn class="bg-primary btn" @click="deletePods(selectedPod['metadata']['name'])"><v-icon>mdi-restart</v-icon> Redémarrer</v-btn>
      </div>
    </PageHeader>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card class="d-flex" style="max-height:40px;">
          <div class="flex-grow-1">
            <v-tabs class="custom-tabs" v-model="tab">
              <v-tab value="detail">Détails</v-tab>
              <v-tab value="dirs">Arborescense</v-tab>
              <v-tab value="console">Console</v-tab>
              <v-tab value="two">Environnement</v-tab>
              <v-tab value="logs">Logs</v-tab>
              <v-tab value="mysql" @click="swichOnMysqlPod()" v-if="hasMysqlPod()">Mysql</v-tab>
              <v-tab value="postgresql" @click="swichOnPostgrePod()" v-if="hasPostgrePod()">Postgresql</v-tab>
            </v-tabs>
          </div>
          <div>
            <v-menu v-if="selectedPod !== null">
              <template v-slot:activator="{ props }">
                <div v-bind="props" class="pod-select">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <div class="d-flex">
                        <div class="pr-3 pt-1 pl-2">
                          <div
                              v-if="(selectedPod['status']['phase'] == 'Running' || selectedPod['status']['phase'] == 'Succeeded') && searchPods(selectedPod)"
                              style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                          <div class="bg-error"
                               v-if="(selectedPod['status']['phase'] != 'Running' && selectedPod['status']['phase'] != 'Succeeded') || !searchPods(selectedPod)"
                               style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                        </div>
                        <div>
                          <div style="font-size:0.9em;color:#575757;">{{ selectedPod['metadata']['name'] }}</div>
                          <div v-if="false && searchPods(selectedPod)"  style="font-size:0.8em;color:#aaa;margin-top:-5px;">
                            {{ formatDate(selectedPod['metadata']['creationTimestamp']) }}
                          </div>
                          <div v-if="false && !searchPods(selectedPod)" class="text-danger" style="font-size:0.8em;color:#aaa;margin-top:0px;">
                            Ce pod n'existe plus, veuillez sélectionner un pod dans la liste
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <v-icon style="font-size:1.5em;color:#aaa;">mdi-chevron-down</v-icon>
                    </div>
                  </div>
                </div>
              </template>
              <v-list>
                <v-list-item v-for="item in ressources" class="pl-0 pr-0 pt-0 pb-0"
                             @click="loadLogs(item['metadata']['name'], item, 'manual')">
                  <v-list-item-title class="item-list">
                    <div class="d-flex">
                      <div class="pr-3 pt-2">
                        <div v-if="item['status']['phase'] == 'Running' || item['status']['phase'] == 'Succeeded'"
                             style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                        <div class="bg-error"
                             v-if="item['status']['phase'] != 'Running' && item['status']['phase'] != 'Succeeded'"
                             style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                      </div>
                      <div>
                        <div style="font-size:0.8em;color:#575757;">{{ item['metadata']['name'] }}</div>
                        <div style="font-size:0.7em;color:#aaa;margin-top:-8px;">
                          {{ formatDate(item['metadata']['creationTimestamp']) }}
                        </div>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="margin-top:-24px;" v-if="tab === 'logs' && !loading">
      <v-col cols="12">
        <v-card style="border-top-left-radius: 0px;border-top-right-radius: 0px;">
          <div class="d-flex" style="max-height:20px;margin-top:-20px;margin-bottom:20px;">
            <div class="flex-grow-1 d-flex" style="margin-top:10px;margin-left:-15px;padding-left:10px;padding-top:2px;position:relative;">
              <input type="text" placeholder="recherche ..." v-model="logSearch" style="height:35px;border:solid 1Px #eee;width:100%;padding-left:10px;"></input>
              <v-icon style="position:absolute;right:10px;color:#ddd;margin-top:7px;">mdi-search-web</v-icon>
            </div>
            <div class="d-flex mt-5 mr-0 pl-4 namespace-btn" @click="autoScroll=!autoScroll">
              <v-icon v-if="!autoScroll">mdi-pause</v-icon>
              <v-icon v-if="autoScroll">mdi-arrow-down-thick</v-icon>
              <div class="ml-1">{{ autoScroll ? 'Auto défilement' : 'Auto défilement' }}</div>
            </div>
            <div class="d-flex mt-5 mr-4 pl-4 namespace-btn" @click="live=!live">
              <v-icon v-if="!live">mdi-note-text</v-icon>
              <v-icon v-if="live">mdi-broadcast</v-icon>
              <div class="ml-1">{{ live ? 'Temps réel' : 'Historique' }}</div>
            </div>
            <div class="d-flex mt-5 pr-5 namespace-btn" @click="consoleTheme()">
              <v-icon>mdi-theme-light-dark</v-icon>
              <div>{{ consoleNight ? 'Noir' : 'Blanc' }}</div>
            </div>


            <div style="font-size:0.9em;margin-top:20px;color:#ccc;">
              <v-icon style="font-size:1.5em;">mdi-clock-outline</v-icon>
              {{ dateSync }}
            </div>
          </div>

        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-card-text style="margin-top:-10px;margin-left:-5px;min-width:calc(100% + 10px)">
        <v-tab-items v-if="tab === 'detail'">
          <v-row  v-if="selectedPod !== null">
            <v-col cols="3">
              <v-card class="h-100">
                <div style="font-size:1.2em;font-weight:500;">Disque</div>
                <div style="font-size:0.8em;color:#aaa;margin-top:-5px;">Utilisation disque dédié</div>
                <div v-if="selectedPod['volume'] === undefined" class="text-center" style="color:#ccc;padding-top:17px;"><v-icon style="font-size:2em;">mdi-harddisk-remove</v-icon> Pas de disque dédié</div>
                <div class="d-flex mt-3" v-if="selectedPod['volume'] !== undefined">
                  <div style="margin-top:5px;font-weight:500;font-size:0.8em;color:#aaa;flex:1;">{{ ((selectedPod['volume'][0]['usage'] / 1024).toFixed(2) + ' Go / ' + (selectedPod['volume'][0]['storage'] / 1024).toFixed(2)) }} Go </div>
                  <div style="color:#aaa;font-size:0.8em;padding-top:5px;">{{ selectedPod['volume'][0]['name'] }}</div>
                </div>
                <ProgressBar v-if="selectedPod['volume'] !== undefined && selectedPod['volume'][0]['usagePercent'] !== undefined" height="15" :percent="selectedPod['volume'][0]['usagePercent']" :color="selectedPod['volume'][0]['usagePercent'] < 80 ? '' : 'e36622'"></ProgressBar>

              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="h-100">
                <div style="font-size:1.2em;font-weight:500;">Réseau</div>
                <div style="font-size:0.8em;color:#aaa;margin-top:-5px;">Ports utilisés sur le conteneur</div>

                <div class="d-flex">
                  <div class="d-flex mt-5 mr-6" v-for="port in selectedPod['describe']['ports']">
                    <v-icon style="color:#aaa;">mdi-network-pos</v-icon>
                    <div class="ml-1"> {{ port['containerPort'] }}</div>
                  </div>
                </div>

              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="h-100">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <div style="font-size:1.2em;font-weight:500;">Ram</div>
                    <div style="font-size:0.8em;color:#aaa;margin-top:-5px;">Ram utilisé par le pod</div>
                  </div>
                  <div>
                    <v-tooltip>
                      <template v-slot:activator="{ props }">
                        <v-icon v-bind="props">mdi-information-outline</v-icon>
                      </template>
                      <span style="max-width:200px;text-wrap: auto;white-space: normal!important;word-wrap: break-word!important;">Si le pods ne dispose pas de limite de ram, le calcul de la mémoire maximum est basé sur la ram restante pour le noeud qui héberge le pod. Exemple (RAM utilisé du noeud 2 Go, Ram max du noeud 8 Go, la ram max du pods sera donc de 6 Go)</span>
                    </v-tooltip>
                    </div>
                </div>
                <div class="mt-3">
                  <div class="d-flex" v-if="selectedPod['ram'] !== undefined">
                    <div style="margin-top:5px;font-weight:500;font-size:0.8em;color:#aaa;flex:1;">{{ (selectedPod['ram']['used'] + ' Mo / ' + selectedPod['ram']['total'].toFixed(0)) }} Mo </div>
                    <div v-if="!selectedPod['ram']['limit']" class="text-error" style="font-size:0.8em;padding-top:5px;">Non limité</div>
                  </div>
                  <ProgressBar height="15" v-if="selectedPod['ram'] !== undefined" :percent="((selectedPod['ram']['used'] / selectedPod['ram']['total']) * 100)" :color="(selectedPod['ram']['used'] / selectedPod['ram']['total'] * 100) < 80 ? '' : 'e36622'"></ProgressBar>
                  <div v-if="selectedPod['ram'] === undefined" class="text-center" style="color:#ccc;padding-top:12px;"><v-icon style="font-size:2em;">mdi-chart-timeline-variant</v-icon> Aucune information</div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="h-100">
                <div style="font-size:1.2em;font-weight:500;">Noeud disque</div>
                <div style="font-size:0.8em;color:#aaa;margin-top:-5px;">{{ selectedPod['spec']['nodeName'] }}</div>
                <div class="mt-3">
                  <div class="d-flex" v-if="selectedPod['node'] !== undefined">
                    <div style="margin-top:5px;font-weight:500;font-size:0.8em;color:#aaa;flex:1;">{{ ((selectedPod['node']['usage'] / 1024).toFixed(2) + ' Go / ' + (selectedPod['node']['size'] / 1024).toFixed(2)) }} Go </div>
                  </div>

                  <ProgressBar height="15" v-if="selectedPod['node'] !== undefined" :percent="((selectedPod['node']['usage'] / selectedPod['node']['size']) * 100)" :color="(selectedPod['node']['usage'] / selectedPod['node']['size'] * 100) < 80 ? '' : 'e36622'"></ProgressBar>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row style="margin-top:5px;">
            <v-col cols="12" v-if="selectedPod !== null">
              <v-card>
                <div class="">
                  <div style="font-size:1.2em;font-weight:500;">Informations du pod</div>
                  <div class="mt-5" style="font-size:0.95em;">
                    <v-row>
                      <v-col cols="6">
                        <div class="top-card-title font-weight-bold">Cluster</div>
                        <div class="top-card-data">{{ cluster['name'] }}</div>
                      </v-col>
                      <v-col cols="6">
                        <div class="top-card-title font-weight-bold">Namespace</div>
                        <div class="top-card-data">{{ selectedPod['metadata']['namespace'] }}</div>
                      </v-col>
                      <div class="card-sep"></div>

                      <v-col cols="12">
                        <div class="top-card-title font-weight-bold">Image</div>
                        <div class="top-card-data">{{ selectedPod['spec']['containers'][0]['image'] }}</div>
                      </v-col>
                      <div class="card-sep"></div>

                      <v-col cols="12">
                        <div class="top-card-title font-weight-bold">Arguments</div>
                        <div class="top-card-data">{{
                            (selectedPod['spec']['containers'][0] !== undefined && selectedPod['spec']['containers'][0]['args'] !== undefined && selectedPod['spec']['containers'][0]['args'][0] !== undefined) ? selectedPod['spec']['containers'][0]['args'][0] : '-'
                          }}
                        </div>
                      </v-col>

                    </v-row>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row style="margin-top:5px">
            <v-col cols="12" v-if="selectedPod !== null && selectedPod['spec']['containers'][0]['env'] !== undefined &&  selectedPod['spec']['containers'][0]['env'].length > 0">
              <v-card>
                <div class="">
                  <div style="font-size:1.2em;font-weight:500;">Environnement actif</div>
                  <div class="mt-5" style="font-size:0.85em;">
                    <v-row>
                      <template v-for="env in selectedPod['spec']['containers'][0]['env']">
                        <v-col cols="12">
                          <div class="top-card-title font-weight-bold">{{ env['name'] }}</div>
                          <div class="top-card-data">{{ env['value'] ?? '-' }}</div>
                        </v-col>
                        <div class="card-sep"></div>
                      </template>

                    </v-row>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-items>
        <v-tab-items v-if="tab === 'two'">
          <v-row>
            <v-col cols="12">
              <v-card style="text-align:center;padding:40px" class="text-error" v-if="projects.length === 0">
                Pas d'environnement pour ce projet
              </v-card>
              <v-card v-for="repo in projects" class="mb-4">
                <div style="font-size:1.2em;font-weight:500;">{{ repo['name'] }}</div>
                <v-table class="w-100 custom-table" style="min-width:calc(100% + 40px)" >
                  <thead class="text-left">
                  <tr>
                    <th style="padding-left:30px;">Clé</th>
                    <th>Type</th>
                    <th>Valeur</th>
                    <th width="50">Action</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="project in repo['variables']" >
                    <td class="text-left" style="padding-left:30px;">{{ project['key'] }}</td>
                    <td class="text-left">{{ project['variable_type'] }}</td>
                    <td class="text-left">{{ project['value'].length > 100 ? project['value'].substring(0, 100) + '...' : project['value'] }}</td>
                    <td>
                      <v-menu>
                        <template v-slot:activator="{ props }">
                          <div v-bind="props" class="pt-3 pb-3 pr-3 pl-3 cursor-pointer">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </div>
                        </template>
                        <v-list class="custom-vlist">
                          <v-list-item class="pl-0 pr-0">
                            <v-list-item-title class="pt-3 pb-3 pr-6 pl-6 cursor-pointer list-item-title">Actions</v-list-item-title>
                            <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover" @click="editVariables(repo['id'], project)"><div class="d-block flex-grow-1 pr-4">Modifier</div><v-icon class="icon">mdi-chevron-right</v-icon></v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                  </tbody>
                </v-table>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-items>
        <v-tab-items v-if="tab === 'logs'">
          <v-row class="h-100" style="min-height:75vh;margin-bottom:15px;">
            <v-col cols="12" class="h-100">
              <v-card :style="consoleNight ? 'background:#1e2431;' : ''" class="h-100">
                <div v-if="logs !== undefined && logs.length === 0 && !loadingLog && selectedPod !== null" class="pl-4 pr-4 pt-4 pb-4 text-center w-100">
                  Aucun log pour <b class="text-primary">{{ selectedPod['metadata']['name'] }}</b>
                </div>
                <div v-else-if="loadingLog" class="pl-4 pr-4 pt-4 pb-4 text-center w-100">
                  Chargement des logs pour <b class="text-primary">{{ selectedPod['metadata']['name'] }}</b>
                  <loader></loader>
                </div>
                <div v-else style="overflow-y:scroll;max-height:75vh;" ref="logContainer">
                  <template v-for="(log, logindex) in logs">
                    <div class="d-flex pl-3 pr-3" v-if="logFilterResult(log['content']) && logs[logindex + 1] !== undefined && (log['container_id'] === logs[logindex + 1]['container_id'])">
                      <div style="color:#999;min-width:140px;font-size:0.9em;"
                           v-if="logs[logindex + 1] !== undefined && (log['container_id'] === logs[logindex + 1]['container_id']) && log['date'] !== null">
                        {{
                          log['date'] !== null ? moment(log['date']['date']).tz('Europe/Paris').format('DD/MM/YYYY HH:mm:ss') : ''
                        }}
                      </div>
                      <div :style="consoleNight ? 'color:#98c766;' : 'color:#25a125;'" style="font-size:0.9em;"
                           v-if="logs[logindex + 1] !== undefined && (log['container_id'] === logs[logindex + 1]['container_id'])">
                        {{ log['content'] }}
                      </div>
                      <div class="other-pod"
                           v-if="logs[logindex + 1] !== undefined && (log['container_id'] !== logs[logindex + 1]['container_id'])">
                        CHANGEMENT DE POD : {{ logs[logindex + 1]['container_id'] }}
                      </div>
                    </div>
                  </template>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-items>
        <v-tab-items v-if="tab === 'mysql'">
          <MysqlCard :selectedPod="selectedPod" :cluster="2" v-if="selectedPod !== null"></MysqlCard>
        </v-tab-items>
        <v-tab-items v-if="tab === 'postgresql'">
          <PostgreCard :selectedPod="selectedPod" :cluster="2" v-if="selectedPod !== null"></PostgreCard>
        </v-tab-items>
        <v-tab-items v-if="tab === 'dirs'">
          <ThreeCard :selectedPod="selectedPod" :cluster="2" v-if="selectedPod !== null"></ThreeCard>
        </v-tab-items>
        <v-tab-items v-if="tab === 'console'">
          <ConsoleCard :selectedPod="selectedPod" :cluster="2" v-if="selectedPod !== null"></ConsoleCard>
        </v-tab-items>
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script>
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from '@/services/DashboardService.js';
import { fr } from 'date-fns/locale';
import moment from 'moment-timezone';
import 'moment/locale/fr';

import VueTree from '@ssthouse/vue3-tree-chart';
import '@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css';
import axios from 'axios';
import Loader from '@/components/ui/Loader.vue';
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
import ProjectsService from '@/services/ProjectsService.js';
import ProgressBar from '@/components/ui/progressBar/ProgressBar.vue';
import MysqlCard from '@/components/ui/bloc/MysqlCard.vue';
import PostgreCard from '@/components/ui/bloc/PostgreCard.vue';
import ThreeCard from '@/components/ui/bloc/ThreeCard.vue'
import ConsoleCard from '@/components/ui/bloc/ConsoleCard.vue'

moment.locale('fr');

export default {
  name: 'NamespacePage',
  computed: {
    fr() {
      return fr
    }
  },
  components: { ConsoleCard, ThreeCard, PostgreCard, MysqlCard, ProgressBar, PageHeader, Loader, GuestCard, BackButton, VueTree },
  data() {
    return {
      cancelTokenSource: axios.CancelToken.source(),
      loading: false,
      loadingLog: false,
      logSearch: null,
      ressources: [],
      namespaces: [],
      ramMax: 0,
      date: new Date(),
      logs: [],
      moment: moment,
      sync: null,
      syncPods: null,
      selectedPod: null,
      projects: [],
      module: 'log',
      cluster: null,
      dateSync: new Date(),
      live: true,
      autoScroll: true,
      tab: 'detail',
      consoleNight: false,
      modalEnv: false,
      modalEnvform: {name: null, type: null, value : null},
      isFormValid: false,
    }
  },
  unmounted() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
    this.cancelTokenSource.cancel('Request canceled by the user.');
  },
  methods: {
    async loadProjects() {
      this.loading = true
      this.projects = [];
      try {
        ProjectsService.gitlabVariableByNamespace(this.$route.params.id).then(response => {
          this.projects = response
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    consoleTheme() {
      this.consoleNight=!this.consoleNight
      localStorage.setItem('logTheme', this.consoleNight);
    },
    logFilterResult(log) {
      if(this.logSearch !== null && this.logSearch !== '' && this.logSearch !== undefined) {
        return log.toLowerCase().includes(this.logSearch.toLowerCase());
      }

      return true;
    },
    async loadDashboard(first) {
      clearInterval(this.syncPods);

      try {
        let activePod = (this.selectedPod !== null) ? this.selectedPod['metadata']['name'] : '';
        await DashboardService.loadNamespace(this.$route.params.cluster, this.$route.params.id, activePod).then(response => {
          this.ressources = response['pods'];

          if(this.tab === 'mysql' && first) {
            this.swichOnMysqlPod();
          }
          else if(this.tab === 'postgresql' && first) {
            this.swichOnPostgrePod();
          }

          this.cluster = response['cluster'];

          if (first && this.selectedPod === null) {
            this.selectedPod = this.ressources[0];
            this.loadLogs(this.ressources[0]['metadata']['name'], this.ressources[0])
          }
          else if (first && this.selectedPod !== null) {
            this.loadLogs(this.selectedPod['metadata']['name'], this.selectedPod)
          }
          else {
            this.ressources.forEach(pod => {
              if(this.selectedPod !== null && this.selectedPod['metadata']['name'] === pod['metadata']['name']){
                this.selectedPod = pod;
              }
            });
          }
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.syncPods = setTimeout(() => {this.loadDashboard(false)}, 3000);
        this.loading = false;
      }
    },
    async deletePods(pod) {
      this.loading = true;
      await DashboardService.deletePods(this.$route.params.cluster, this.$route.params.id, pod).then(response => {
        this.loading = false;
        this.module = 'log';
        this.logs = [];
        clearInterval(this.sync);
        this.cancelTokenSource.cancel('Request canceled by the user.');
        this.cancelTokenSource = axios.CancelToken.source();
      });
    },
    searchPods(pod) {
      let search = false;

      this.ressources.forEach(item => {
        if(item['metadata']['name'] === pod['metadata']['name']) {
          search = true;
        }
      })

      return search;
    },
    async loadLogs(pod, item, type = 'auto') {
      clearInterval(this.sync);

      if(type === 'manual') {
        this.selectedPod = item;
        this.loading = true;
        this.loadDashboard(false);
        this.cancelTokenSource.cancel('Request canceled by the user.');
        this.cancelTokenSource = axios.CancelToken.source();
        this.loadingLog = true;
        this.logs = [];
      }

      try {
        await DashboardService.loadLog(this.$route.params.cluster, this.$route.params.id, item['metadata']['name'], this.cancelTokenSource, item['metadata']['labels']['app'], this.live).then(response => {
          if(response['logs'] !== undefined) {
            this.logs = response['logs']
            this.loadingLog = false;
            this.dateSync = moment(response['synchro']).tz('Europe/Paris').format('HH:mm:ss')

            if(this.$refs.logContainer !== undefined && this.$refs.logContainer !== null && this.autoScroll) {
              this.$refs.logContainer.scrollTop = this.$refs.logContainer.scrollHeight;
            }
          }
        });
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.sync = setTimeout(() => {this.loadLogs(pod, item)}, 1000);
      }

    },
    formatDate(dateString) {
      return this.moment(dateString).fromNow();
    },
    editVariables(projectId, project) {
      this.modalEnv = true;
      this.modalEnvform.projectId = projectId;
      this.modalEnvform.name = project['key'];
      this.modalEnvform.type = project['variable_type'];
      this.modalEnvform.value = project['value'];
    },
    sendForm(refresh = false) {
      console.error(refresh);
      ProjectsService.gitlabVariableUpdate( this.modalEnvform.projectId,  this.modalEnvform.name, this.modalEnvform.value, this.modalEnvform.type, refresh).then(response => {
        if(response['success']) {
          this.modalEnv = false;
          this.loadProjects();
        } else {
          alert('erreur de mise a jour');
        }
      })
    },
    hasMysqlPod() {
      let search = false;
      this.ressources.forEach(pod => {
        if(!search) {
          search = pod['metadata']['name'].includes('mysql')
        }
      });

      return search;
    },
    hasPostgrePod() {
      let search = false;
      this.ressources.forEach(pod => {
        if(!search) {
          search = pod['metadata']['name'].includes('postgre')
        }
      });

      return search;
    },
    swichOnMysqlPod() {
      let search = false;
      this.ressources.forEach(pod => {
        if(pod['metadata']['name'].includes('mysql')) {
          this.selectedPod = pod
        }
      });

      return search;
    },
    swichOnPostgrePod() {
      let search = false;
      this.ressources.forEach(pod => {
        if(pod['metadata']['name'].includes('postgre')) {
          this.selectedPod = pod
        }
      });

      return search;
    }
  },
  watch: {
    tab(newValue, oldValue) {
      if(newValue === null) {
        this.tab = 'detail';
        newValue = 'detail';
      }

      localStorage.setItem('NamespaceTabs', newValue);
    }
  },
  beforeMount() {
    let localTheme = localStorage.getItem('logTheme');
    if(localTheme !== undefined)
      this.consoleNight = localTheme;

    let localTabs = localStorage.getItem('NamespaceTabs');
    if(localTabs !== undefined) {
      this.tab = localTabs
    }

    this.loadDashboard(true);
    this.loadProjects();
  }

}
</script>
