<template>
  <div class="d-flex progress-bar-all">
    <div class="progress-bar" :style="'width:'+ $props.percent +'%;background:#'+ $props.color+';height:'+$props.height+'px'">
      <div class="progress-bar-value" :style="'line-height:'+$props.height+'px;'">{{ $props.percent.toFixed(0) }} %</div>
    </div>
    <div class="progress-bar-container" :style="'width:'+ (100- $props.percent) +'%;height:'+$props.height+'px'">
      <div class="progress-bar-value" :style="'line-height:'+$props.height+'px;'">{{ (100- $props.percent).toFixed(0) }} %</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  components: {},
  props: {
    percent : {
      type : Number,
      required: true
    },
    color : {
      type : String,
      required: false
    },
    height : {
      type : String,
      required: false,
      default: 20
    }
  },
  data() {
    return {}
  }
};
</script>

<style scoped>
/* Conteneur de la barre de progression */
.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: width 2s ease-in-out, height 2s ease-in-out;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 100%); /* Dégradé de profondeur */
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2); /* Ombre pour l'effet de profondeur */
}

/* Fond hachuré */
.progress-bar-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%; /* Double la largeur pour éviter les espaces */
  height: 100%;
  background: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.05) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.05) 50%,
      rgba(0, 0, 0, 0.05) 75%,
      transparent 75%,
      transparent 100%
  );
  background-size: 20px 20px;
  z-index: 1; /* Fond */
  animation: hatching 3s linear infinite;
}

/* Barre de progression */
.progress-bar {
  width: 0%; /* Modifier cette largeur pour ajuster la progression */
  height: 20px;
  background-color: #51a175;
  border-radius: 5px;
  position: relative;
  z-index: 2; /* Au-dessus du fond */
  margin-right: 4px;
  transition: width 2s ease-in-out;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2); /* Ombre interne pour la barre */
}

.progress-bar-container > .progress-bar-value {
  color: #575757;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  opacity: 0;
}
.progress-bar-all:hover > .progress-bar-container > .progress-bar-value {
  opacity: 1;
}

.progress-bar > .progress-bar-value {
  color: #fff;
  text-align: center;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.progress-bar-all:hover > .progress-bar > .progress-bar-value {
  opacity: 1;
}

/* Animation des hachures */
@keyframes hatching {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20px); /* Correspond à background-size */
  }
}
</style>
