<template>
  <v-container class="h-100 pt-4">
    <PageHeader subtitle="Ajouter un utilisateur" v-if="!loading">
      <router-link :to="'/users/add'">
        <v-btn class="bg-primary btn" @click="createUser" :disabled="!valid">Sauvegarder</v-btn>
      </router-link>
    </PageHeader>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="form.firstname"
                      :counter="10"
                      :rules="nameRules"
                      label="First name"
                      hide-details
                      required
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="form.lastname"
                      :counter="10"
                      :rules="nameRules"
                      label="Last name"
                      hide-details
                      required
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="form.email"
                      :rules="emailRules"
                      label="E-mail"
                      hide-details
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       md="4">
                  <v-select
                      label="Select"
                      v-model="form.type"
                      :rules="typeRules"
                      :items="['admin', 'developer', 'other']"
                  ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="form.password"
                      :rules="passwordRules"
                      label="Password"
                      type="password"
                      hide-details
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12"
                                md="4">
                <v-select
                    label="Select"
                    v-model="form.namespaces"
                    :rules="typeRules"
                    :items="this.namespaces"
                    multiple
                ></v-select>
              </v-col>
              </v-row>
            </v-container>
          </v-form>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import {useAuthStore} from "@/stores/AuthStore.js";
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
moment.locale('fr');

export default {
  name: 'UsersAdd',
  computed: {
    fr() {
      return fr
    }
  },
  components: { PageHeader, GuestCard, BackButton },
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  mounted() {
    if(localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }

    UsersService.namespaceList().then(response => {
      this.namespaces = response['namespaces'];
    });
  },
  data() {
    return {
      loading: false,
      valid: false,
      namespaces: [],
      form: {firstname: null, lastname: null, email: null, type: null, password: null, namespaces: []},
      passwordRules: [
        value => {
          if (value) return true

          return 'Name is required.'
        },
      ],
      nameRules: [
        value => {
          if (value) return true

          return 'Name is required.'
        },
        value => {
          if (value?.length <= 10) return true

          return 'Name must be less than 10 characters.'
        },
      ],
      typeRules: [
        value => {
          if (value) return true

          return 'Name is required.'
        }
      ],
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail is required.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
    }
  },
  methods: {
    async createUser() {
      this.loading = true
      try {
        await UsersService.createUser(this.form).then(response => {
          this.users = response['users'];
          this.$router.push({ name: 'Users' });
        })
        useSnackbarStore().openSnackbar({
          text: this.$t('FORGOT_PASSWORD_PAGE.SUCCESS'),
          color: 'success'
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    }
  }

}
</script>


<style scoped>

</style>
