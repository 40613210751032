<template>
  <v-app  :class="{'theme-light' : !useThemeStore().black, 'theme-dark' : useThemeStore().black}">
    <v-navigation-drawer v-model="drawer">
      <div>
        <div class="logo-nav mb-4 mt-2" style="margin-top:0px;">
          <img src="../assets/logo.svg" style="width:50px;">
          <div class="text-primary pl-1">
            <div style="font-size:1.7em;font-weight:600!important;color:#1e2931;">QuantOps</div>
          </div>
        </div>

        <div class="nav-cat">Général</div>

        <router-link :to="'/dashboard'">
          <div class="nav-item"
               :class="{'nav-item-active' : $route.name === 'Dashboard'}">
            <div class="nav-item-text">

              <v-icon>mdi-view-dashboard-outline</v-icon>
              Dashboard
            </div>
          </div>
        </router-link>

        <router-link :to="'/homeoffice'" v-if="Authservice.hasAccess('dev_homeoffice')">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'homeOfficePage'}">
            <div class="nav-item-text">
              <v-icon>mdi-home-account</v-icon>
              Télétravail
            </div>
          </div>
        </router-link>

        <router-link :to="'/monitoring'" v-if="Authservice.hasAccess('dev_monitoring')">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'monitoringPage'}">
            <div class="nav-item-text">
              <v-icon>mdi-chart-bar</v-icon>
              Monitoring
            </div>
          </div>
        </router-link>

        <div class="nav-cat" v-if="Authservice.hasAccess('dev_cluster') || Authservice.hasAccess('cluster_manage')">Devops</div>

        <router-link :to="'/clustersdev'" v-if="Authservice.hasAccess('dev_cluster') || Authservice.hasAccess('cluster_manage')">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'ClusterDev' || $route.name === 'Namespace'}">
            <div class="nav-item-text">
              <v-icon>mdi-kubernetes</v-icon>
              Clusters
            </div>
          </div>
        </router-link>


        <div class="nav-cat" v-if="Authservice.hasAccess('users_manage') || Authservice.hasAccess('users_manage') || Authservice.hasAccess('group_manage') || Authservice.hasAccess('projects_manage') || Authservice.hasAccess('gitlab_manage') || Authservice.hasAccess('homeoffice_manage')">Admin</div>

        <router-link v-if="Authservice.hasAccess('users_manage')" :to="'/users'">
          <div class="nav-item"
               :class="{'nav-item-active' : $route.name === 'Users' || $route.name === 'UsersAdd' || $route.name === 'UsersEdit'}">
            <div class="nav-item-text">
              <v-icon>mdi-account-outline</v-icon>
              Utilisateurs
            </div>
          </div>
        </router-link>

        <router-link v-if="Authservice.hasAccess('group_manage')" :to="'/groups'">
          <div class="nav-item"
               :class="{'nav-item-active' : $route.name === 'Groups' || $route.name === 'GroupsAdd' || $route.name === 'GroupsEdit'}">
            <div class="nav-item-text">
              <v-icon>mdi-account-group</v-icon>
              Groupes
            </div>
          </div>
        </router-link>

        <router-link v-if="Authservice.hasAccess('projects_manage')" :to="'/projects'">
          <div class="nav-item"
               :class="{'nav-item-active' : $route.name === 'Projects' || $route.name === 'ProjectsAdd' || $route.name === 'ProjectsEdit'}">
            <div class="nav-item-text">
              <v-icon>mdi-source-repository</v-icon>
              Projets
            </div>
          </div>
        </router-link>

        <router-link v-if="Authservice.hasAccess('gitlab_manage')" :to="'/repositories'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'RepositoriesManagePage'}">
            <div class="nav-item-text">
              <v-icon>mdi-gitlab</v-icon>
              Gitlab
            </div>
          </div>
        </router-link>

        <router-link v-if="Authservice.hasAccess('homeoffice_manage')" :to="'/homeoffice/admin'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'HomeOfficeAdminListPage'}">
            <div class="nav-item-text">
              <v-icon>mdi-home</v-icon>
              Télétravail
            </div>
          </div>
        </router-link>



        <div class="d-flex justify-start align-content-center right-top-bar login-bg" :class="{'login-bg-active' : accountMenu}">
          <img src="../assets/avatar.png" style="width:45px;height:45px;border-radius:50%;" class="avatar">
          <div class="pl-3 pt-0 flex-grow-1">
            <div>{{ userFullName }}</div>
            <div style="color:#8d6868;font-size:0.8em;">{{ user.type }}</div>
          </div>
          <div class="justify-center d-flex align-content-center pt-3">
            <v-icon>mdi-chevron-down</v-icon>
          </div>

          <v-menu activator="parent" v-model="accountMenu">
            <v-list>
              <v-list-item>
                <v-list-item-title @click="myAccount()">
                  <div class="nav-item-text">
                  <v-icon class="mr-3">mdi-account</v-icon>
                  Mon compte
                </div>
                </v-list-item-title>
                <v-divider class="mb-2 mt-2"></v-divider>
                <v-list-item-title @click="logout">
                  <div class="nav-item-text text-danger">
                  <v-icon class="mr-3 text-danger">mdi-power</v-icon>
                  Déconnexion
                </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

      </div>
    </v-navigation-drawer>

    <v-app-bar class="overflow-visible" v-if="false">

      <div class="d-flex" @mouseover="showCluster=true" @mouseleave="showCluster=false">
        <div class="topbar-info position-relative overflow-visible">
          <div class="topbar-info-box">
            <div class="text-uppercase"><span style="font-size:1em;font-weight:600;margin-right:3px;">2</span> <span style="font-size:0.9em;">Cluster</span></div>
            <div class="d-flex pt-2">
              <div style="background:#30a76d;border-radius:50%;width:10px;height:10px;margin-right:10px;margin-top:2px;"></div>
              <div style="font-size:0.75em;text-transform: uppercase;">En ligne</div>
            </div>
          </div>
          <div v-if="showCluster" style="position:fixed;top:79px;left:-1px;padding:5px 15px;background:#fff;border:solid 1px #eee;">


            <div style="position:absolute;font-size:0.8em;right:15px;color:#ccc;top:10px;"><v-icon style="font-size:1.2em;margin-top:0;">mdi-clock-outline</v-icon> {{moment(clusters['lastUpdate']['date']).format('HH:mm:ss') }}</div>
            <template v-for="cluster in clusters['datas']">
              <div class="d-flex pt-2 pb-2">
                <div style="font-size:0.8em;font-weight:600;text-transform: uppercase;">{{ cluster['name'] }}</div>
              </div>
              <div v-for="node in cluster['datas']" class="pb-3 d-flex">
                <div class="pr-2 pt-1">
                  <v-icon style="font-size:1.6em;color:#000000de">mdi-server-outline</v-icon>
                </div>
                <div class="flex-grow-1">
                  <div style="font-size:0.8em;color:#575757;">{{ node['name'] }}</div>
                  <div style="font-size:0.8em;color:#9a9999;">{{  (node['ram']['used']).toFixed(0) }} / {{ (node['ram']['total']).toFixed(0) }} Mo</div>
                  <div style="background:#bcdaf3;height:8px;width:100%;position:relative;">
                    <div v-if="node['ram']['used'] / node['ram']['total'] * 100 <= 80" style="background:#0287f9;height:100%;" :style="{width : (node['ram']['used'] / node['ram']['total'] * 100) + '%'}"></div>
                    <div v-if="node['ram']['used'] / node['ram']['total'] * 100 > 80" style="background:#f96102;height:100%;" :style="{width : (node['ram']['used'] / node['ram']['total'] * 100) + '%'}"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="pl-0 cursor-pointer"  @mouseover="showError=true" @mouseleave="showError=false">

        <div class="topbar-info-box">
          <div class="text-uppercase"><span style="font-size:1em;font-weight:600;margin-right:3px;"></span> <span style="font-size:0.9em;">Informations</span></div>
          <div class="d-flex pt-2">
            <div style="background:#30a76d;border-radius:50%;width:10px;height:10px;margin-right:10px;margin-top:2px;"  v-if="clusters['errorsCount'] === 0"></div>
            <div style="background:#b63553;border-radius:50%;width:10px;height:10px;margin-right:10px;margin-top:2px;"  v-if="clusters['errorsCount'] > 0"></div>
            <div style="font-size:0.75em;text-transform: uppercase;"  v-if="clusters['errorsCount'] === 0 || clusters['errorsCount'] === undefined">Aucun problème</div>
            <div style="font-size:0.75em;text-transform: uppercase;"  v-if="clusters['errorsCount'] > 0">{{ clusters['errorsCount'] }} {{ clusters['errorsCount'] > 1 ? 'erreurs' : 'erreur' }}</div>
          </div>
        </div>
        <div v-if="showError" style="position:fixed;top:79px;left:-1px;padding:5px 15px;background:#fff;border:solid 1px #eee;">
        <template v-for="project in clusters['projects']">
          <div class="d-flex text-error pt-2 pb-2" v-if="project['state'] === 'down'">
            <div class="pr-2 pt-1">
              <v-icon style="font-size:1.6em;">mdi-web-cancel</v-icon>
            </div>
            <div class="flex-grow-1">
              <div style="font-size:0.8em;font-weight:600;color:#575757;">{{ project['url'] }}</div>
              <div>Site web indisponible</div>
            </div>
          </div>
        </template>
        </div>
      </div>
      <div class="flex-grow-1"></div>
      <div class="theme-icon" @click="theme()">
        <v-tooltip v-if="!useThemeStore().black" location="bottom" text="Theme Matthieu">
          <template v-slot:activator="{ props }">
          <v-icon v-bind="props">mdi-weather-night</v-icon>
          </template>
        </v-tooltip>
        <v-tooltip v-if="useThemeStore().black" location="bottom" text="Theme Mireille">
          <template v-slot:activator="{ props }">
          <v-icon v-bind="props">mdi-white-balance-sunny</v-icon>
          </template>
        </v-tooltip>
      </div>
      <div class="d-flex justify-center align-content-center pr-5 right-top-bar" style="margin:0;height:64px;padding-left:20px;">
        <img src="../assets/avatar.png" style="width:45px;height:45px;margin-top:12px;border-radius:50%;" class="avatar">
        <div class="pl-3 pt-3">
          <div>{{ userFullName }}</div>
          <div style="color:#8d6868;font-size:0.8em;">{{ user.type }}</div>
        </div>
      </div>
    </v-app-bar>

    <v-main class="pb-4">
      <slot/>
    </v-main>
  </v-app>
</template>

<script>
//get the user from the store and the drawer state, and the mutation to change the drawer state and the computed property to get the user
import { useAuthStore } from '@/stores/AuthStore.js'
import router from '@/router/index.js'
import DashboardService from "@/services/DashboardService.js";
import moment from 'moment';
import { useThemeStore } from '@/stores/ThemeStore.js';
import AuthService from '@/services/AuthService.js';

export default {
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  data() {
    return {
      drawer: true,
      showCluster: false,
      showError: false,
      clusters: [],
      moment: moment,
      Authservice: AuthService,
      accountMenu : false,
    }
  },
  mounted() {
    this.loadCluster();
  },
  computed: {
    user() {
      return this.authStore.user;
    },
    userFullName() {
      return `${ this.user?.firstname } ${ this.user?.lastname }`;
    }
  },
  methods: {
    useThemeStore,
    async loadCluster() {
      // DashboardService.clusterBar().then(response => {
      //   this.clusters = response;
      //   setTimeout(() => {this.loadCluster()}, 5000);
      // });
    },
    async logout() {
      this.authStore.logout();
      router.push({ name: 'Login' });
    },
    theme() {
      useThemeStore().switch();
    }
  }
}
</script>

<style>
</style>
