<template>
  <div class="circular-progress-container" :style="{ fontSize: $props.size / 11 + 'px' }">
    <svg class="circular-progress" :width="$props.size" :height="$props.size">
      <defs>
        <radialGradient id="background-gradient" cx="50%" cy="50%" r="50%">
          <stop offset="0%" stop-color="#555" />
          <stop offset="100%" stop-color="#d4d4d4" />
        </radialGradient>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" :stop-color="$props.disabled ? '#ccc' : '#51a175'" />
          <stop offset="100%" :stop-color="$props.disabled ? '#ccc' : '#3c8058'" />
        </linearGradient>
      </defs>
      <circle
          :cx="radius"
          :cy="radius"
          :r="radius - strokeWidth / 2"
          fill="none"
          stroke="url(#background-gradient)"
          :stroke-width="strokeWidth"
          :stroke="$props.disabled ? '#ccc' : 'url(#background-gradient)'"
      />
      <circle
          :cx="radius"
          :cy="radius"
          :r="radius - strokeWidth / 2"
          fill="none"
          :stroke="$props.disabled ? '#ccc' : 'url(#gradient)'"
          :stroke-width="strokeWidth"
          stroke-linecap="round"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="strokeOffset"
          class="progress-circle"
      />
    </svg>
    <div class="progress-content">
      <div class="progress-value" :style="$props.disabled ? { color: '#ccc' } : { color: '#51a175' }">
        {{ $props.currentValue }}/{{ $props.maxValue }}
      </div>
      <div class="progress-title" :style="$props.disabled ? { color: '#ccc' } : { color: '#666' }">
        {{ $props.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Pods',
    },
    currentValue: {
      type: Number,
      required: true,
      default: 0,
    },
    maxValue: {
      type: Number,
      required: true,
      default: 100,
    },
    size: {
      type: Number,
      default: 150,
    },
    strokeWidth: {
      type: Number,
      default: 15,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    percentage() {
      return (this.currentValue / this.maxValue) * 100;
    },
    radius() {
      return this.size / 2;
    },
    circumference() {
      return 2 * Math.PI * (this.radius - this.strokeWidth / 2);
    },
    strokeOffset() {
      return this.circumference - (this.percentage / 100) * this.circumference;
    },
  },
};
</script>

<style scoped>
.circular-progress-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circular-progress {
  transform: rotate(-90deg);
  transform-origin: center;
}

.progress-circle {
  transition: stroke-dashoffset 1s ease-in-out;
}

.progress-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-value {
  font-size: 1.4em;
  font-weight: bold;
}

.progress-title {
  font-size: 1.1em;
  margin-top: 0.1em;
}
</style>
