<template>
  <v-card class="h-100 card-action overflow-visible"
          @click="item['pods']['pods'].length > 0 ? $router.push('/namespace/2/' + item['metadata']['name']) : ''">
    <div class="d-flex">
      <div class="flex-grow-1">
        <div class="d-flex mb-2 pl-3 pr-3 pb-0" style="z-index:999;position:relative;color:#548bff;">
          <div class="flex-grow-1 d-flex">
            <div class="flex-grow-1">
              <div class="text-uppercase" style="font-weight:500">{{ item['metadata']['name'] }}</div>
              <div style="font-size:0.9em;color:#ccc;">{{ item['ram'].toFixed(0) }} Mo de ram</div>
              <div class="text-left pt-2 pb-2 text-error" style="font-size:0.8em;font-style:italic;"
                   v-if="item['pods']['pods'].length === 0">Aucun pods pour ce namespace
              </div>
            </div>
            <div>
              <div class="circular-progress-container overflow-hidden">
                <div class="fadeLeft">
                  <CircularBar class="circular-bar" :disabled="item['pods']['pods'].length === 0"
                               :currentValue="item['count']['podsAlive']" :max-value="item['count']['podsMax']"
                               :size="80"></CircularBar>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex pt-2 pb-2" style="font-size:0.85em;" v-if="item['isKube']">
              <div class="ml-2" v-for="namespace in item['namespaces']">
                <router-link :to="'/namespace/' + namespace">
                  <div
                      style="color:#548bff;background:#f1f4ff;margin-top:4px;border-radius:10px;padding:5px 10px;font-size:0.75em;">
                    <v-icon style="font-size:1.7em;color:#548bff">mdi-kubernetes</v-icon>
                    {{ namespace }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { useAuthStore } from '@/stores/AuthStore.js';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import CircularBar from '@/components/ui/progressBar/CircularBar.vue';

export default {
  name: 'PodCard',
  components: { CircularBar },
  props: {
    item: {
      type: Object,
      required: true,
      default: null
    }
  },
  beforeMount() {
    this.item = this.$props.item;
    let podsMax = 0;
    let podsAlive = 0;
    let ramUsed = 0;

    this.item['pods']['pods'].forEach(pod => {
      podsMax++;

      if (pod['status']['phase'] == 'Running' || pod['status']['phase'] == 'Succeeded') {
        podsAlive++;
      }

      ramUsed += pod['memory'];
    })

    this.item['count'] = { podsAlive, podsMax }
    this.item['ram'] = ramUsed
  },
  data() {
    return {
      item: null
    }
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  computed: {
    user() {
      return this.authStore.user;
    }
  }
};
</script>

<style scoped>
.circular-bar {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.card-action:hover {
  .circular-bar {
    animation: shake 0.6s ease-in-out forwards;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
