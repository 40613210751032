import ResponseConstant from '@/constants/responseConstant.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'

export const handleResponseError = (error, $t) => {
  if (error.response?.data && error.response.data.key in ResponseConstant) {
    useSnackbarStore().openSnackbar({ title : "Une erreur est survenue", text: $t("API_RESPONSE." + ResponseConstant[error.response.data.key]), color: 'error' })
  } else {
    useSnackbarStore().openSnackbar({ text: $t('API_RESPONSE.UNKNOWN'), color: 'error' })
  }
}
