<template>
  <v-container class="h-100 d-flex">
    <v-row align="center" justify="center">
      <v-card
        variant="elevated"
        class="pt-8 pb-8"
        style="border-radius:0px;box-shadow: 0 0 20px 0 rgba(0,0,0,0.07), 0 5px 5px 0 rgba(0,0,0,0.06)!important;"
      >
        <div :class="(isLogoCentered ? 'text-center' : 'text-left') + ' mb-4 px-8 d-flex'" style="justify-content: center;align-items: center;">
          <img alt="Logo QuantOps" class="logo" src="@/assets/logo.svg" style="width:60px">
          <div class="text-primary pl-1">
            <div style="font-size:2.3em;font-weight:600!important;color:#1e2931;">QuantOps</div>
          </div>
        </div>
        <v-divider class="mb-6 mt-5"></v-divider>
        <div v-if="$slots.header" class="px-8">
          <slot name="header" />
        </div>
        <div class="px-8">
          <slot />
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'GuestCard',
  props: {
    isLogoCentered: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.v-card {
  width: 100%;
  max-width: 410px;
}
</style>
