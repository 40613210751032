import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    visible: false,
    title: '',
    text: '',
    duration: 100000,
    color: 'success',
    icon: '',
    multiline: false,
  }),
  actions: {
    openSnackbar(parameters){
      this.visible = true
      this.text = parameters.text
      this.title = parameters.title
      this.color = 'bg-' + parameters.color
      this.icon = (parameters.color === 'error') ? 'mdi-close' : 'mdi-information-symbol'
      this.multiline = typeof parameters.text === 'string' ? parameters.text.length > 30 : false
    }
  }
})
