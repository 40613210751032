<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader/>
      </v-col>
    </v-row>

    <PageHeader v-if="!loading" subtitle="Demande de télétravail">
      <router-link :to="'/homeoffice/create'">
        <v-btn class="bg-primary btn">
          <v-icon>mdi-plus</v-icon>
          Faire une demande
        </v-btn>
      </router-link>
    </PageHeader>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card class="p-0">
          <div class="d-flex">
            <v-table class="w-100 custom-table ml-0">
              <thead class="text-left">
              <tr>
                <th>Date de début</th>
                <th>Date de fin</th>
                <th style="max-width:200px;">Etat</th>
                <th width="50">Action</th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="project in requests">

                <td class="text-left d-flex">
                  <div class="d-flex justify-center align-content-center"
                       style="justify-content: center;align-items: center;padding-left:10px;">
                    {{ moment(project['date']['date']).format('DD/MM/YYYY') }}
                  </div>
                </td>
                <td class="text-left">{{ moment(project['date']['date']).format('DD/MM/YYYY') }}</td>
                <td style="max-width:200px;" class="text-left">
                  <div class="chips-success" v-if="project['validated'] === true">
                    <v-icon>mdi-check</v-icon>
                    {{ formatStatus(project['validated']) }}
                  </div>
                  <div class="chips-error" v-if="project['validated'] === false">
                    <v-icon>mdi-cancel</v-icon>
                    {{ formatStatus(project['validated']) }}
                  </div>
                  <div class="chips-wait" v-if="project['validated'] === null">
                    <v-icon>mdi-clock</v-icon>
                    {{ formatStatus(project['validated']) }}
                  </div>
                </td>

                <td>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="pt-3 pb-3 pr-3 pl-3 cursor-pointer">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </div>
                    </template>
                    <v-list class="custom-vlist">
                      <v-list-item class="pl-0 pr-0">
                        <v-list-item-title class="pt-3 pb-3 pr-6 pl-6 cursor-pointer list-item-title">Actions
                        </v-list-item-title>
                        <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover"
                                           @click="deleteRequest(project)">
                          <div class="d-block flex-grow-1 pr-4">Supprimer</div>
                          <v-icon class="icon">mdi-chevron-right</v-icon>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              </tbody>
            </v-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import { fr } from 'date-fns/locale';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import JobService from '@/services/JobService.js';
import Loader from '@/components/ui/Loader.vue';
import { format } from 'date-fns';
import { useAuthStore } from '@/stores/AuthStore.js';
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
import AuthService from '@/services/AuthService.js';

moment.locale('fr');

export default {
  name: 'HomeOfficeListPage',
  computed: {
    fr() {
      return fr
    },
    user() {
      return this.authStore.user;
    }
  },
  components: { PageHeader, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      requests: [],
      date: new Date(),
      moment: moment,
      hour: parseInt(format(new Date(), 'HH', { locale: fr }))
    }
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    async loadHomeOffice() {
      this.loading = true
      try {
        JobService.homeOfficeList().then(response => {
          this.requests = response['requests'];
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    formatStatus(status) {
      if (status === null) {
        return 'En attente';
      } else if (status === true) {
        return 'Validé'
      } else if (status === false) {
        return 'Refusé'
      }
    },
    async deleteRequest(project) {
      if (project['validated'] !== null) {
        alert('Vous ne pouvez pas supprimer une demande validé ou refusé');
        return;
      }

      let check = confirm('Etes vous sure ?')

      if (check) {
        JobService.homeOfficeRequestDelete(project['id']).then(() => {
          useSnackbarStore().openSnackbar({
            text: 'Demande de télétravail supprimée avec succès',
            color: 'success'
          })
          this.loadHomeOffice();
        });
      }

    }
  },
  mounted() {
    if(!AuthService.hasAccess('dev_homeoffice')) {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loading = true;
    this.loadHomeOffice();
  }

}
</script>
