<template>
  <v-container class="h-100 pt-4">
    <PageHeader subtitle="Ajouter un groupe" v-if="!loading">
        <v-btn class="bg-primary btn" @click="createUser" :disabled="!valid">Sauvegarder</v-btn>
    </PageHeader>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="form.name"
                      :counter="10"
                      :rules="nameRules"
                      label="nom du groupe"
                      hide-details
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div v-for="access in this.access"> <input type="checkbox" v-model="form['perms'][access['id']]" /> {{ access['name'] }}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import {useAuthStore} from "@/stores/AuthStore.js";
import PageHeader from '@/components/ui/bloc/PageHeader.vue';
import GroupsService from '@/services/GroupsService.js';
moment.locale('fr');

export default {
  name: 'GroupsEdit',
  computed: {
    fr() {
      return fr
    }
  },
  components: { PageHeader, GuestCard, BackButton },
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  mounted() {
    if(localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }


    GroupsService.accessList().then(response => {
      this.access = response;

      response.forEach(access => {
        this.form['perms'][access['id']] = false;
      })

      GroupsService.get(this.$route.params.id).then(response => {
        this.form = response;

        response.forEach(access => {
          this.form['perms'][access['id']] = false;
        })
      });

    });
  },
  data() {
    return {
      loading: false,
      valid: false,
      access: [],
      form: {name: null, perms: []},
      nameRules: [
        value => {
          if (value) return true

          return 'Name is required.'
        },
      ],
    }
  },
  methods: {
    async createUser() {
      this.loading = true
      try {
        await GroupsService.update(this.$route.params.id, this.form).then(response => {
          this.users = response['users'];
          this.$router.push({ name: 'Groups' });
        })
        useSnackbarStore().openSnackbar({
          text: this.$t('FORGOT_PASSWORD_PAGE.SUCCESS'),
          color: 'success'
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    }
  }

}
</script>


<style scoped>

</style>
