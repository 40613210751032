import api from '@/services/ApiService'

class GroupsService {
  async groupList() {
    const response = await api.get('/groups')
    return response.data
  }

  async get(id) {
    const response = await api.get('/groups/' + id)
    return response.data
  }

  async accessList() {
    const response = await api.get('/access')
    return response.data
  }

  async create(form) {
    const response = await api.post('/groups/create', form)
    return response.data
  }

  async update(id, form) {
    const response = await api.put('/groups/'+ id, form)
    return response.data
  }

  async delete(id) {
    const response = await api.delete('/groups/' + id)
    return response.data
  }
}

export default new GroupsService()
